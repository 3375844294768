import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router";
import FeatherIcon from "feather-icons-react";
import Moment from "react-moment";
import * as moment from "moment";
import {
  Button,
  SelectMenu,
  Avatar,
  Badge,
  Pill,
  Position,
  Spinner,
  Alert,
  SearchInput,
} from "evergreen-ui";
import { Switch, Tooltip } from "antd";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import Countdown from "react-countdown";

export class Tasks extends Component {
  state = {
    search_mode: false,
    tab_mode: true,
    unassigned_orders: [],
    assigned_orders: [],
    delayed_orders: [],
  };

  componentWillReceiveProps(props) {
    if (props.orders && props.orders.length > 0) {
      let unassigned_orders = [];
      let assigned_orders = [];
      let delayed_orders = [];
      props.orders.map((order, index) => {
        if (order.auto_assign_status == "assigned") {
          assigned_orders.push(order);
        } else {
          unassigned_orders.push(order);
        }
        if (order.time_diffs >= 45) {
          delayed_orders.push(order);
        }
      });

      this.setState({ unassigned_orders, assigned_orders, delayed_orders });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            position: "sticky",
            top: "0px",
            zIndex: "10",
            padding: "10px",
          }}>
          <SearchInput
            onChange={(e) => {
              this.props.searchOrders(e.target.value);
              if (e.target.value.length > 0) {
                this.setState({
                  search_mode: true,
                  tab_mode: false,
                });
              } else {
                this.setState({
                  search_mode: false,
                  tab_mode: true,
                });
              }
            }}
            placeholder={localStorage.getItem("select_task")}
            style={{ width: "23vw" }}
          />
        </div>
        {this.state.tab_mode && (
          <div style={{ display: "flex" }}>
            <Tabs
              activeTab='1'
              ulClassName=''
              activityClassName='bg-primary'
              onClick={(event, tab) => console.log(event, tab)}>
              {/* <Tab title={`Assigned (${this.state.assigned_orders.length})`} className="mr-1"> */}
              {/* <Tab title={`localStorage.getItem('assigned')} (${this.state.assigned_orders.length}) className="mr-1"> */}

              <Tab
                title={`${localStorage.getItem("assigned")} (${
                  this.state.assigned_orders.length
                })`}
                className='mr-1'>
                {this.renderOrders(this.state.assigned_orders)}
              </Tab>

              <Tab
                title={`${localStorage.getItem("unassigned")} (${
                  this.state.unassigned_orders.length
                })`}
                className='mr-1'>
                {this.renderOrders(this.state.unassigned_orders)}
              </Tab>

              <Tab
                title={`${localStorage.getItem("delayed")} (${
                  this.state.delayed_orders.length
                })`}
                className='mr-1'>
                {this.renderOrders(this.state.delayed_orders)}
              </Tab>
            </Tabs>
          </div>
        )}

        {this.state.search_mode && this.renderOrders(this.props.filterdOrders)}
      </React.Fragment>
    );
  }

  renderToolTip = (order) => {
    return (
      <div>
        {order.order_status_id == 6 ? (
          <>
            <div
              className='d-flex justify-content-between'
              style={
                !order.left_time > 0
                  ? {
                      padding: "4px",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "3px",
                      fontSize: "12px",
                      marginBottom: "7px",
                    }
                  : {
                      padding: "4px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      borderRadius: "3px",
                      fontSize: "12px",
                      marginBottom: "7px",
                    }
              }>
              <div>First KM : {order.distance_order.first_mile} </div>
              <div>
                ETA TO PICKUP :{" "}
                <Countdown date={Date.now() + order.left_time * 100} />
              </div>
            </div>
          </>
        ) : null}

        {order.order_status_id == 7 ? (
          <>
            <div
              className='d-flex justify-content-between'
              style={
                !order.left_time > 0
                  ? {
                      padding: "4px",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "3px",
                      fontSize: "12px",
                      marginBottom: "7px",
                    }
                  : {
                      padding: "4px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      borderRadius: "3px",
                      fontSize: "12px",
                      marginBottom: "7px",
                    }
              }>
              <div> Second KM : {order.distance_order.last_mile}</div>
              <div>
                ETA TO DELIVERY :{" "}
                <Countdown date={Date.now() + order.left_time * 100} />
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  renderOrders(orders) {
    return (
      <div className={"vertical_scroll"} style={{ height: "72vh" }}>
        {orders.map((order, index) => (
          <React.Fragment key={order.id}>
            <div
              className='card_tab'
              style={
                this.props.selectedOrder &&
                this.props.selectedOrder.id === order.id
                  ? { background: "#f2effb" }
                  : null
              }
              onClick={() => {
                this.props.deployOrderPop(order);
              }}>
              <div className='d-flex'>
                <div style={{ width: "70%" }}>
                  <p className='mb-0' style={{ fontSize: "13px" }}>
                    {order.client_order_identifier}{" "}
                  </p>
                  <p className='mb-0 text-muted' style={{ fontSize: "10px" }}>
                    {order.pickup_name}
                  </p>
                  <Badge color='red'>
                    <Moment fromNow={true}>{order.created_at}</Moment>
                  </Badge>{" "}
                  {order.auto_assign_status === "assigned" && (
                    <Tooltip
                      placement={"right"}
                      title={localStorage.getItem("delivery_pin")}>
                      <Badge color='green'>{order.delivery_pin}</Badge>
                    </Tooltip>
                  )}{" "}
                  <Tooltip
                    placement='topLeft'
                    title={this.renderToolTip(order)}>
                    <Pill>ETA</Pill>
                  </Tooltip>
                </div>
                {order.orderstatus && (
                  <div
                    style={{
                      width: "30%",
                      borderLeft: "0.1px solid #0000002b",
                      paddingLeft: "10px",
                    }}>
                    <Badge>{order.orderstatus.name}</Badge>
                  </div>
                )}
              </div>
              {/* < Ink /> */}
            </div>

            {this.props.autoMode === true && (
              <React.Fragment>
                {order.auto_assign_status === "waitingapproval" && (
                  <div
                    className='auto-assign-ongoing-div'
                    style={{ backgroundColor: "#faad14" }}>
                    <span style={{ marginRight: "10px" }}>
                      {localStorage.getItem("waiting_for_approval")}
                    </span>{" "}
                  </div>
                )}
                {order.auto_assign_status === "ongoing" && (
                  <div className='auto-assign-ongoing-div'>
                    <span style={{ marginRight: "10px" }}>
                      {localStorage.getItem("auto_assigning")}
                    </span>{" "}
                    <Spinner color={"red"} size={16} />
                  </div>
                )}
                {order.auto_assign_status === "assigned" && (
                  <div className='auto-assign-div'>
                    <span style={{ marginRight: "10px" }}>
                      {localStorage.getItem("order_assigned")} |{" "}
                      {order.agent ? order.agent.name : "UNASSIGNED "} |
                    </span>
                    {localStorage.getItem("show_ex") == "true" &&
                      order.order_status_id == 7 && (
                        <Tooltip
                          placement='top'
                          title={localStorage.getItem(
                            "complete_the_order_manually"
                          )}>
                          <FeatherIcon
                            size={13}
                            onClick={() =>
                              this.props.manaualOrderComplete(order)
                            }
                            icon={"check-circle"}
                          />
                        </Tooltip>
                      )}
                  </div>
                )}
                {order.auto_assign_status === "failed" && (
                  <div className='auto-assign-div-failed'>
                    <span style={{ marginRight: "10px" }}>
                      {localStorage.getItem("auto_assign_failed")}
                    </span>
                    <Tooltip
                      placement='top'
                      title={localStorage.getItem("retry_auto_assignation")}>
                      <FeatherIcon
                        size={13}
                        onClick={() => this.props.retryAutoAssign(order)}
                        icon={"refresh-cw"}
                      />
                    </Tooltip>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default Tasks;
