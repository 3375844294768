import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from "react-router";
import FeatherIcon from "feather-icons-react";
import Moment from "react-moment";
import * as moment from "moment";

import 'react-best-tabs/dist/index.css';

import { Form, Input, Button, Space, Divider, Select, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
export class CreateTask extends Component {

    render() {
        return (
            <Form onFinish={(e) => console.log(e)} autoComplete="off">

                <div>Basic Details</div>
                <Space style={{ display: 'flex', }} align="baseline">
                    <Form.Item name="task_id" >
                        <Input placeholder="Unique Task ID (Optional)" />
                    </Form.Item>
                    <Form.Item name="team_id" rules={[{ required: true, message: 'Team is not selected' }]} style={{width: '10vw'}}>
                        <Select
                            placeholder="Select the handeling team"
                            allowClear
                            style={{width: '10vw'}}
                        >
                            {this.props.teams.map((team) => team.id !== 'all' && (
                                <Option value={team.id}>{team.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="delivery_charge" style={{width: '10vw'}} rules={[
                        { required: true, message: 'Delivery charge is required, minimum 0' }
                    ]} >
                        <InputNumber placeholder="Task Delivery Charge" style={{width: '100%'}} />
                    </Form.Item>
                    <Form.Item name="order_cod" rules={[
                        { required: true, message: 'Delivery charge is required, minimum 0' }
                    ]} style={{width: '10vw'}}>
                        <InputNumber placeholder="Cash On Delivery Amount"   style={{width: '100%'}}/>
                    </Form.Item>
                </Space>
                <Divider />
                <div>Location Setup</div>
                <Space style={{ display: 'flex' }} align="inline">

                    <Form.Item name="pickup_lat" rules={[{ required: true, message: 'Pickup Latitude is missing' }]}>
                        <Input placeholder="Pickup Latitude" />
                    </Form.Item>
                    <Form.Item name="pickup_lng" rules={[{ required: true, message: 'Pickup Longitude is missing' }]}>
                        <Input placeholder="Pickup Longitude" />
                    </Form.Item>
                    <Divider type="vertical" />
                    <Form.Item name="drop_lat" rules={[{ required: true, message: 'Drop Latitude is missing' }]}>
                        <Input placeholder="Drop Latitude" />
                    </Form.Item>
                    <Form.Item name="drop_lng"  rules={[{ required: true, message: 'Drop Longitude is missing' }]}>
                        <Input placeholder="Drop Longitude" />
                    </Form.Item>
                </Space>
                <Form.Item name={'Address'} >
                    <Input.TextArea placeholder="Delivery Address Eg. 4th Floor, Times New York, NY, 679322"/>
                </Form.Item>
                <Divider />

                <div>Assignation Setup</div>
               
                <Divider />

                <div>Add Items</div>
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        rules={[{ required: true, message: 'Missing name' }]}
                                    >
                                        <Input placeholder="Item Name" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'id']}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder="Item ID (optional)" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'quantity']}
                                        rules={[{ required: true, message: 'Missing Quantity Min 1' }]}
                                    >
                                        <Input placeholder="Quantity" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'amount']}
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder="Amount (Optional)" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Item
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={true}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        );
    }

}

export default CreateTask;